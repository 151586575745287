import { combineReducers } from 'redux';
import { getActions } from 'helper';
import authReducer from 'reducers/authReducer';
import commonReducer from 'reducers/commonReducer';

const getReducer = (reducer, type) => {
  const Action = getActions(type);
  return {
    [reducer]: commonReducer(Action.GETALL),
    [`${reducer}_details`]: commonReducer(Action.GETONE),
    [`${reducer}_create`]: commonReducer(Action.CREATE),
    [`${reducer}_update`]: commonReducer(Action.UPDATE),
    [`${reducer}_delete`]: commonReducer(Action.DELETE),
  };
};
export default combineReducers({
  authReducer,
  ...getReducer('profile', 'PROFILE'),
  ...getReducer('category', 'CATEGORY'),
  ...getReducer('artwork', 'ARTWORK'),
  ...getReducer('testimonials', 'TESTIMONIALS'),
  ...getReducer('article', 'ARTICLE'),
  ...getReducer('count', 'COUNT'),
  ...getReducer('client', 'CLIENT'),
  ...getReducer('allarticles', 'ALLARTICLE'),
  ...getReducer('archivedarticles', 'ARCHIVEDARTICLE'),
  ...getReducer('allartworks', 'ALLARTWORK'),
  ...getReducer('featuredArticle', 'FEATUREDARTICLE'),
  ...getReducer('tag', 'TAG'),
  ...getReducer('profileArticle', 'PROFILE_ARTICLE'),
  ...getReducer('profileArtwork', 'PROFILE_ARTWORK'),
  ...getReducer('followers', 'FOLLOWERS'),
  ...getReducer('sentRequests', 'SENT_REQUESTS'),
  ...getReducer('following', 'FOLLOWING'),
  ...getReducer('followRequests', 'FOLLOW_REQUESTS'),
  ...getReducer('likes', 'LIKES'),
  ...getReducer('bookmarks', 'BOOKMARKS'),
  ...getReducer('config', 'CONFIG'),
  ...getReducer('downloads', 'DOWNLOADS'),
  ...getReducer('puzzle', 'PUZZLE'),
  ...getReducer('artists', 'ARTISTS'),
  ...getReducer('packages', 'PACKAGES'),
  ...getReducer('expiredsubscriptions', 'EXPIREDSUBSCRIPTIONS'),
  ...getReducer('activesubscriptions', 'ACTIVESUBSCRIPTIONS'),
  ...getReducer('message', 'MESSAGE'),
  ...getReducer('reply','REPLY'),
  ...getReducer('redeemPoints', 'REDEEMPOINTS'),
  ...getReducer('purchaseHistory', 'PURCHASE'),
  ...getReducer('notifications', 'NOTIFICATIONS'),
  ...getReducer('faq','FAQ'),
  ...getReducer('readstatus','READSTATUS'),
  ...getReducer('cms','CMS'),
  ...getReducer('announcements','ANNOUNCEMENTS'),
  ...getReducer('eventticket', 'EVENTTICKET'),
  ...getReducer('events', 'EVENTS'),
  ...getReducer('gallery', 'GALLERY'),


  // ...getReducer('follow','FOLLOW')


});
