/* eslint-disable require-jsdoc */
import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import thunk from 'redux-thunk';

import rootReducer from './../reducers/index';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '/api/v1.0/',
  responseType: 'json',
});
import { persistStore, persistReducer } from "redux-persist";
import localforage from 'localforage';

const inititalState = {};
const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: function ({ getState, dispatch, getSourceAction }, req) {
          //...
          req.headers.Authorization = sessionStorage.getItem(
            'jwtToken',
          );
          return req;
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          //...
          return error;
        },
      },
    ],
    response: [
      {
        success({ getState, dispatch, getSourceAction }, req) {
          // ...
          return req;


        },
        error({ getState, dispatch, getSourceAction }, error) {
          // ...
          const errorStatus = error?.response?.status;
          if (errorStatus) {
            if (errorStatus === 401) {
              sessionStorage.removeItem('jwtToken');
            }
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig)))
);
export { store };
