import Actions from './../actions/types';
import { isEmpty } from '../helper';

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  errors: null,
};

/**
 *Auth Reducer
 * @param {*} state
 * @param {*} action
 */
export default function (state = initialState, action) {
  switch (action.type) {
  case Actions.SET_CURRENT_USER:
    return {
      isAuthenticated: !isEmpty(action.payload),
      isSubscribed: action?.payload?.subscription,
      user: action.payload,
    };
  case Actions.GET_AUTH_ERRORS:
    return {
      ...state,
      errors: action?.error,
    };
  default:
    return state;
  }
}
