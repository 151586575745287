import axios from "axios";
import localforage from "localforage";

/**
 * getActions
 * @param {*} TYPE 
 * @returns 
 */
export const getActions = (TYPE) => ({
  CREATE: `CREATE_${TYPE}`,
  GETONE: `GET_${TYPE}_DETAILS`,
  GETALL: `GET_${TYPE}`,
  UPDATE: `UPDATE_${TYPE}`,
  DELETE: `DELETE_${TYPE}`,
});
export const checkSpecialCharacters = (string) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return format.test(string);
}
export const hasNumber = (myString) => {
  return /\d/.test(myString);
}

export const getTotalRewards = (data) => {
  return (data?.filter(val => val?.status === 'added')?.reduce((total, val) => ((val?.points) + total), 0) -
    data?.filter(val => val?.status === 'requested')?.reduce((total, val) => ((val?.points) + total), 0) -
    data?.filter(val => val?.key === 'redeemed')?.reduce((total, val) => ((val?.points) + total), 0)
  )
}


export const removeSpaceAndSpecialChars = (string) => {
  return string.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ').replace(/\s\s+/g, " ");
}

export const removeptag = (string) => {
  return string.replace(/<[^>]+>/g, '');
}

/**
 * Decamelizer funtion
 * @param {String} str String to decamelize
 * @param {String} separator seperator
 * @return {String} Decamelized string
 */
export function deCamelizer(str, separator = ' ', capitalize = false) {
  if (!str) return null;
  const returnString = str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`);
  return capitalize ? returnString.toUpperCase() : returnString.toLowerCase();
}

/**
 * isEmpty
 * @param {*} value 
 * @returns 
 */
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

/**
 * ClassNames
 * @param {*} classes 
 * @returns 
 */
export const ClassNames = (classes) => {
  return classes.join(" ");
};

/**
 * getObjectProp
 * @param {*} obj 
 * @param {*} prop 
 * @returns 
 */
export const getObjectProp = (obj, prop) => {
  if (typeof obj === "string") {
    return obj;
  }
  if (typeof prop === "undefined") {
    return false;
  }
  const _index = prop.indexOf(".");
  if (_index > -1) {
    return getObjectProp(
      obj?.[prop?.substring(0, _index)],
      prop?.substr(_index + 1)
    );
  }
  return obj?.[prop];
};


/**
 * setNestedProp
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
export const setNestedProp = (obj = {}, key = "", value) => {
  let keys = [];
  // console.log(key)
  if (key) keys = key?.split(".");
  const [first, ...rest] = keys;
  return {
    ...obj,
    [first]: rest.length
      ? setNestedProp(obj[first], rest.join("."), value)
      : value,
  };
};

export const dateFormat = (date) => {
  const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  if (date) {
    return `${new Date(date).getDate()} ${monthNames[new Date(date).getMonth()]
      } ${new Date(date).getFullYear()}`
  }
  return null
}
var censorWord = (str) => {
  return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
}
export const setWithExpiry = (key, value, expiration) => {
  const now = new Date()
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + expiration,
  }
  localforage.setItem(key, JSON.stringify(item))
}
export const censorEmail = (email) => {
  var arr = email.split("@");
  return censorWord(arr[0]) + "@" + censorWord(arr[1]);
}
export const timeFormat = (date) => {
  var hours = new Date(date).getHours();
  var minutes = new Date(date).getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


export const matchWith = (val1, val2) => {
  return !(val1 === val2)
}

export const hasOnlySpecialCharater = (val) => {
  var pattern = /^[^a-zA-Z0-9]+$/;
  return (pattern.test(val));
}
export const hasOnlyNumAccountNumber = (val) => {
  return isNaN(val)
}
/**
 *
 * @param {*} data
 */
export const validate = (data, fields) => {

  const errors = {};
  fields.forEach((field) => {
    if (isEmpty(data?.[field.name]) && field.required) {
      errors[field.name] = `The  ${field.fieldName?.toLowerCase() || field.label?.toLowerCase()} can't be empty`;
    }
    if (checkSpecialCharacters(data?.[field.name]) && field?.noSpecialChar) {
      errors[field.name] = `Special characters not allowed`;
    }
    if (data?.[field.name] && field?.noNumbers && hasNumber(data?.[field.name])) {
      errors[field.name] = `Numbers not allowed`;
    }
    if (data?.[field.name] && field?.minLength && data?.[field.name].length < field?.minLength) {
      errors[field.name] = `Please enter atleast ${field?.minLength} characters `;
    }
    if (data?.[field.name] && field?.isPhone && (`${data?.[field.name]}`.length !== 10)) {
      errors[field.name] = `Please enter valid phone number`;
    }
    if (data?.[field.name] && field?.matchwith && matchWith(data?.[field.name], data?.[field?.matchwith])) {
      errors[field.name] = `Account number do not match`;
    }
    if (data?.[field.name] && field?.max && field.type !== 'number' && (Number(data?.[field.name]) > Number(field.max))) {
      errors[field.name] = `Please enter maximum ${field.max}.`;
    }
    if (hasOnlySpecialCharater(data?.[field.name]) && field?.onlySpecialChar) {
      errors[field.name] = `Special characters not allowed`;
    }
    if (data?.[field.name] && field?.onlyNumber && hasOnlyNumAccountNumber(data?.[field.name])) {
      errors[field.name] = `Please enter Only Number`;
    }
  });
  return errors;
};


