import React from "react"
import "./NotFound.css"
import logo from "../../assets/logo.png"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import {Helmet} from "react-helmet";


const Error = () => {
  return (
    // <div className="not-found">
    //   <div>
    //     <h1 className="text-404">404</h1>
    //     <div>
    //       <div className="page-not-found">
    //         <h3>PAGE NOT FOUND</h3>
    //       </div>
    //     </div>
    //     <div className="btn">
    //       <button>HOMEPAGE</button>
    //     </div>
    //   </div>
    // </div>
    <div className="not-found">
      <Helmet>
        <title>Page Not Found | ARTZAGE</title>
        <link rel="canonical" href="https://www.artzagesquare.com/?" />
      </Helmet>
      <div>
        <div className="notfound">
          <h1 className="text-404 ">404</h1>

          <h3 className="page-not-found">PAGE NOT FOUND</h3>

          <div className="btn">
            <Link to="/">
              <button>HOMEPAGE</button>
            </Link>
          </div>
        </div>

        
      </div>
    </div>
  )
}

export default Error
