/* eslint-disable indent */

import axios from "axios"
import jwtDecode from "jwt-decode"
import { logOut } from "services/firebase"
import setAuthToken from "../setAuthToken";
import Actions from "./types"
import { getMessaging, getToken, onMessage } from "@firebase/messaging";





export const setCurrentUser = (decoded) => ({
  type: Actions.SET_CURRENT_USER,
  payload: decoded,
})
const URL = process.env.REACT_APP_BASE_URL;

export const loginUser =
  (response, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/login`, {
          email: response?.email,
          token: response?.token,
          key: response?.key,
          password: response?.password
        })
        .then((res) => {
          const { accessToken } = res?.data?.data
          sessionStorage.setItem("jwtToken", accessToken)
          setAuthToken(accessToken)
          // window.console.log(response)
          if (response?.remember) {
            let _email = response?.email;
            let _emailEncoded = window.btoa((_email));
            localStorage.setItem("email", _emailEncoded)
            localStorage.setItem("remember", response?.remember)
          } else {
            localStorage.removeItem("email")
            localStorage.removeItem("password")
            localStorage.removeItem("remember")
          }
          const decoded = jwtDecode(accessToken)
          dispatch(setCurrentUser({ ...decoded, ...res?.data?.data }))
          callback(res?.data?.data)
          const messaging = getMessaging()
          getToken(messaging, {
            vapidKey:
              "BLKNsM9MU0srTpRyj2j0D_5j514uVyjThbfCzecFGuT4KCNs7wUxy32VYPnKHLiTMVK0xLmwCGp9fXXEzbDO2zc",
          })
            .then((token) => {
              axios.put("/profile", { deviceToken: token })
              onMessage(messaging, (payload) => {
                // ...
              })
            })
            .catch((err) => {
              callback(err?.response?.data)
            })
        })
        .catch((err) => {
          callback(err?.response?.data)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err?.response,
          })
        })
    }



export const socialMediaLogin =
  (user, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/socialmedia`, user)
        .then((res) => {
          console.log(res)
          const { accessToken } = res?.data?.data
          sessionStorage.setItem("jwtToken", accessToken)

          setAuthToken(accessToken)
          const decoded = jwtDecode(accessToken)
          const messaging = getMessaging()
          getToken(messaging, {
            vapidKey:
              "BLKNsM9MU0srTpRyj2j0D_5j514uVyjThbfCzecFGuT4KCNs7wUxy32VYPnKHLiTMVK0xLmwCGp9fXXEzbDO2zc",
          })
            .then((token) => {
              axios.put("/profile", { deviceToken: token })
              onMessage(messaging, (payload) => {
                // ...
              })
            })
            .catch((err) => {
              console.log(err)
            })
          callback(res.data)
          dispatch(setCurrentUser(decoded))
        })
        .catch((err) => {
          callback(err?.response?.data?.error)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err?.response?.data,
          })
        })
    }

export const register =
  (user, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/register`, user)
        .then((res) => {
          console.log(res)
          callback(res.data)
        })
        .catch((err) => {
          callback(err?.response?.data)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err?.response?.data,
          })
        })
    }

export const verifyEmail =
  (user, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/verifyemail`, user)
        .then((res) => {
          callback(res.data)
        })
        .catch((err) => {
          callback(err?.response?.data)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err?.response?.data,
          })
        })
    }

export const logoutUser = () => (dispatch) => {
  logOut()
  sessionStorage.removeItem("jwtToken");
  localStorage.removeItem("fblogin");
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  window.location = "/login"
}

export const resetPassword =
  ({ token, userId, password }, callback = () => { }) => (
    dispatch,
  ) => {
    axios
      .post(`${URL}/auth/resetpassword`, { token, userId, password })
      .then((res) => {
        window.console.log(res)
        logoutUser();
        // window.location = '/login';
      })
      .catch((err) => {
        callback(err.response.data.error)
        dispatch({
          type: Actions.GET_AUTH_ERRORS,
          payload: err.response?.data,
        })
      })
  }






export const forgetPassword =
  (dt, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/forgotpassword`, dt)
        .then(() => {
          window.location = "/login"
        })
        .catch((err) => {
          callback(err.response.data.error)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err.response?.data,
          })
        })
    }

export const setNewUser =
  ({ token, id, password, email }, callback = () => { }) =>
    (dispatch) => {
      axios
        .post(`${URL}/auth/newuser-setpassword`, {
          token,
          id,
          password,
          email,
        })
        .then(() => {
          window.location = "/login"
        })
        .catch((err) => {
          callback(err.response.data.error)
          dispatch({
            type: Actions.GET_AUTH_ERRORS,
            payload: err.response?.data,
          })
        })
    }
