import React, { useEffect, useState, Suspense } from 'react';
import { useIdleTimer } from "react-idle-timer";
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from './components/Loader';

/**
 *Authenticated Route function
 * @param {*} param
 */
function AuthenticatedRoute({ component: Component, ...rest }) {
  // const timeout = 10000;
  // const [isIdle, setIsIdle] = useState(false);

  // const handleOnActive = () => setIsIdle(false);
  // const handleOnIdle = () => setIsIdle(true);

  // const idleTimer = useIdleTimer({
  //   timeout,
  //   onActive: handleOnActive,
  //   onIdle: handleOnIdle,
  // });

  // useEffect(() => {
  //   if (isIdle && localStorage.jwtToken) {
  //     store.dispatch(logoutUser());
  //   }
  // }, [isIdle]);

  useEffect(() => {
    if (!rest.isAuthenticated) {
      rest.history.push('/login');
    } 
  }, []);

  // useEffect(() => {
  //   if(isIdle) {
  //     console.log('Trigger')
  //     alert('Idele')
  //   }
  // }, [isIdle]);

  return (
    <>
      {rest.isAuthenticated ? (
        <>
          <div className="authcontent">
            <Route {...rest} exact>
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            </Route>
          </div>
        </>
      ) : null}
    </>
  );
}
// Map state to props
const mapStateToProps = (state) => ({
  ...state.authReducer,
});

// prop types
AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));
