import React from 'react';


import Styles from './loader.module.scss';
import loading from '../../../src/assets/load.gif'
/**
 * Loader
 * @returns 
 */
export default function Loader() {
  return (

    <div className={Styles.loading} >
      <div className={Styles.loader}>
        <img src={loading} className="img-fluid ps-4" alt="Loader gif" />
        <div className={Styles.loading_text}>
          <p className='text-white text-center pb-5 loading-text pe-3'>Please Wait...</p>

        </div>
      </div>
    </div>




  );
}
