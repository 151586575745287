/* eslint-disable require-jsdoc */
import React, { Fragment, Suspense, useEffect, useState } from "react"
import { Route, Switch, withRouter } from "react-router-dom"
import AuthRouter from "./authRouter"
import setAuthToken from "setAuthToken"
import jwtDecode from "jwt-decode"
import { logoutUser, setCurrentUser } from "actions/auth"
import { store } from "store"
import Loader from "components/Loader"
import { routes, unAuthenticatedRoutes } from "config/routes"
import { useIdleTimer } from "react-idle-timer";
import Error from "pages/Error404Page/Error"
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.scss"



const App = () => {
  if (sessionStorage.jwtToken) {
    setAuthToken(sessionStorage.jwtToken)
    const decoded = jwtDecode(sessionStorage.jwtToken)
    store.dispatch(setCurrentUser(decoded))
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      store.dispatch(logoutUser())
    }
  }
  const timeout = 60000 * 15;
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const idleTimer = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (isIdle && sessionStorage.jwtToken) {
      store.dispatch(logoutUser());
    }
  }, [isIdle]);

  sessionStorage.removeItem("fblogin");
  return (
    <Fragment>
      <Helmet>
        {/* Facebook Meta Tags */}
        <meta name="description"
          content="Artzage is an open publishing platform for community bound by love for arts and culture to voice their opinion and can share their literary content and writing or artwork on diverse topic of their choice." />
        <meta property="og:url" content="https://www.artzagesquare.com/"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Artzage"></meta>
        <meta property="og:description" content="Artzage is an open publishing platform for community bound by love for arts and
        culture to voice their opinion and can share their literary content and writing or
        artwork on diverse topic of their choice."></meta>
        <meta property="og:image" content="/uploads/logo.5451ccfd.svg"></meta>

        {/* Twitter Meta Tags logo.5451ccfd.svg */}
        <meta name="twitter:card" content="https://www.artzagesquare.com/"></meta>
        <meta property="twitter:domain" content="https://www.artzagesquare.com/"></meta>
        <meta property="twitter:url" content="https://www.artzagesquare.com/"></meta>
        <meta name="twitter:title" content="ARTZAGE"></meta>
        <meta name="twitter:description" content="Artzage is an open publishing platform for community bound by love for arts and
        culture to voice their opinion and can share their literary content and writing or
        artwork on diverse topic of their choice."></meta>
        <meta name="twitter:image" content="/uploads/logo.5451ccfd.svg"></meta>
        <title>ARTZAGE</title>
        <link rel="canonical" href={`https://www.artzagesquare.com`} />
      </Helmet>
      <Switch>
        {unAuthenticatedRoutes.map(({ link, component: Component }) => (
          <Route path={link} component={Component} key={link} exact>
            <Suspense fallback={<Loader />}>
              <Component />
            </Suspense>
          </Route>
        ))}
        {routes.map(({ link, component: Component }) => (
          <AuthRouter path={link} component={Component} key={link} exact />
        ))}

        <Route path="*" component={Error} />
      </Switch>

    </Fragment>

  )
}

export default withRouter(App)
