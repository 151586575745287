/* eslint-disable require-jsdoc */
import * as firebase from "firebase/app";
import {
  createUserWithEmailAndPassword, FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect,
  signInWithEmailAndPassword, signOut, sendPasswordResetEmail, getRedirectResult, confirmPasswordReset, updatePassword, verifyPasswordResetCode
} from "firebase/auth";
const firebaseConfig = {
  "apiKey": "AIzaSyAQYKFVzKBGkL0Q3nEXdFz3TEzvKNiuILw",
  "authDomain": "artzage.firebaseapp.com",
  "databaseURL": "https://artzage-default-rtdb.firebaseio.com/",
  "projectId": "artzage",
  "storageBucket": "artzage.appspot.com",
  "messagingSenderId": "751161839016",
  "appId": "1:751161839016:web:25390e3ad39bc3ac41619d",
  "measurementId": "G-B45VTK219P"
}
firebase.initializeApp({
  "apiKey": "AIzaSyAQYKFVzKBGkL0Q3nEXdFz3TEzvKNiuILw",
  "authDomain": "artzage.firebaseapp.com",
  "databaseURL": "https://artzage-default-rtdb.firebaseio.com/",
  "projectId": "artzage",
  "storageBucket": "artzage.appspot.com",
  "messagingSenderId": "751161839016",
  "appId": "1:751161839016:web:25390e3ad39bc3ac41619d",
  "measurementId": "G-B45VTK219P"
}

);
export const auth = getAuth();
const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()


/**
 * signInWithGoogle
 * @returns response
 */
export const signInWithGoogle = () => {
  const res = signInWithPopup(auth, googleProvider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      return { success: true, token, user: result.user };
    }).catch((error) => {
      switch (error.code) {
        case "auth/email-already-in-use":
          return { success: false, error: { email: "Email Already in use." } };
        case "auth/invalid-email":
          return { success: false, error: { email: "Invalid Email address" } };
        case "auth/weak-password":
          return { success: false, error: { password: "Please enter a strong password" } };
        default:
          return { success: false, message: error.message, toast: true };
      }

    });
  return res;
}
/**
 * signInWithGoogle
 * @returns response
 */
export const updateUserPassword = (code, password) => {
  const _res = verifyPasswordResetCode(auth, code).then((email) => {
    const accountEmail = email;
    const res = confirmPasswordReset(auth, `${code}`.toString(), password)
      .then((result) => {
        return { success: true, result, accountEmail };
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-action-code":
            return { success: false, message: "Link is expired", toast: true };
          case "auth/weak-password":
            return { success: false, error: { password: "Please enter a strong password" } };
          default:
            return { success: false, message: error.message, toast: true };
        }
      });
    return res;
  });
  return _res
}

/**
 * signInWithGoogle
 * @returns response
 */
export const updateAuthenticatedUserPassword = (password) => {
  const user = auth.currentUser;
  const res = updatePassword(user, password)
    // window.console.log(password)
    .then((result) => {
      // window.console.log(result)
      return { success: true, result };
    })
    .catch((error) => {
      switch (error.code) {
        case "auth/invalid-action-code":
          return { success: false, message: "Link is expired", toast: true };
        case "auth/weak-password":
          return { success: false, error: { password: "Please enter a strong password" } };
        default:
          return { success: false, message: error.message, toast: true };
      }
    });
  return res;
}

/**
 * signInWithGoogle
 * @returns response
 */
export const sendLink = (email) => {
  const res = sendPasswordResetEmail(auth, email)
    .then((result) => {
      return { success: true, result };
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (error.code) {
        case "auth/email-already-in-use":
          return { success: false, error: { email: "Email Already in use." } };
        case "auth/user-not-found":
          return { success: false, error: { email: "Email address doesn't exist" } };
        case "auth/wrong-password":
          return { success: false, error: { password: "Wrong Password" } };
        case "auth/invalid-email":
          return { success: false, error: { email: "Invalid Email address" } };
        default:
          return { success: false, message: error.message };
      }
      // ..
    });
  return res;
}
/**
 * signInWithFacebook
 * @returns response
 */
export const signInWithFacebook = () => {
  const res = signInWithRedirect(auth, facebookProvider)
    .then((result) => {
      console.log(result);
      const token = result.user.accessToken;
      return { success: true, token, user: result.user };
    }).catch((error) => {
      switch (error.code) {
        case "auth/email-already-in-use":
          return { success: false, error: { email: "Email Already in use." } };
        case "auth/account-exists-with-different-credential":
          return { success: false, error: { email: "Account exist with different credentials" } };
        case "auth/invalid-email":
          return { success: false, error: { email: "Invalid Email address" } };
        case "auth/weak-password":
          return { success: false, error: { password: "Please enter a strong password" } };
        default:
          return { success: false, message: error.message, toast: true };
      }

    });
  return Promise.resolve(res);
}

export const redirectFacebook = () => {
  const res = getRedirectResult(auth).then((result) => {
    console.log(result)
    if (result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      const user = result.user;
      return { success: true, token, user: user };
    }

  }).catch((error) => {
    console.log(error);
    switch (error.code) {
      case "auth/email-already-in-use":
        return { success: false, error: { message: "Email Already in use." }, code: error.code };
      case "auth/account-exists-with-different-credential":
        return { success: false, error: { message: "Account exist with different credentials" }, code: error.code };
      case "auth/invalid-email":
        return { success: false, error: { message: "Invalid Email address" }, code: error.code };
      case "auth/weak-password":
        return { success: false, error: { password: "Please enter a strong password" }, code: error.code };
      default:
        return { success: false, message: error.message, code: error.code, toast: true };
    }

    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // AuthCredential type that was used.
    const credential = FacebookAuthProvider.credentialFromError(error);
    // ...
  });
  return Promise.resolve(res);

}
/**
 * signInWithEmailAndPassword
 * @param {*} email 
 * @param {*} password 
 * @returns response
 */
export const signUpWithEmailAndPassword = (email, password) => {
  const res = createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
    const user = userCredential.user;

    return { success: true, token: user.accessToken, user: user };
  }).catch((error) => {
    switch (error.code) {
      case "auth/email-already-in-use":
        return { success: false, error: { email: "Email Already in use." } };
      case "auth/invalid-email":
        return { success: false, error: { email: "Invalid Email address" } };
      case "auth/weak-password":
        return { success: false, error: { password: "Please enter a strong password" } };
      default:
        return { success: false, message: error.message };
    }

  });
  return Promise.resolve(res);
}


/**
 * signInWithEmailAndPassword
 * @param {*} email 
 * @param {*} password 
 * @returns response
 */
export const LoginWithEmailAndPassword = (email, password) => {


  return new Promise((resolve, reject) => {
    // console.log(email);
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      const user = userCredential.user;
      // window.console.log(user?.reloadUserInfo?.passwordHash)
      // Send token to your backend via HTTPS
      // ...
      resolve({ success: true, accessToken: user.accessToken, user: user, });
    }).catch((error) => {

      let err = {}
      switch (error.code) {
        case "auth/email-already-in-use":
          err = { success: false, error: { email: "Email Already in use." }, message: "Email Already in use." };
          break;
        case "auth/user-not-found":
          err = { success: false, error: { email: "Email address doesn't exist" }, message: "Email address doesn't exist" };
          break;
        case "auth/wrong-password":
          err = { success: false, error: { password: "Wrong Password" }, message: "Wrong Password" };
          break;
        case "auth/invalid-email":
          err = { success: false, error: { email: "Invalid Email address" }, message: "Invalid Email address" };
          break;
        default:
          err = { success: false, message: error.message };
      }
      reject(err);

    });
  })


}


export const logOut = () => {
  const res = signOut(auth).then(() => {
    return (true)
    // Sign-out successful.
  }).catch((error) => {
    return (false)
  });
  return Promise.resolve(res);
}