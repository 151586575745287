import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
/**
 * ScrollToTop
 * @param {*} param0 
 * @returns 
 */
function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({ top: '0', left: '0', behavior: 'smooth' });
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);